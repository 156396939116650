import Close from "./Close"

class Navigation {

  static menu = document.querySelector('.nav-bar')

  constructor() {
    this.menu = Navigation.menu
    this.headerHeight = document.querySelector('.header').clientHeight
    if (this.menu) {
      this.listen()
    }
  }

  listen() {
    document.querySelector('.menu-button--open').addEventListener('click', () => {
      this.open()
    })

    document.querySelector('.menu-button--close').addEventListener('click', () => {
      Navigation.close()
    })

    addEventListener('resize', () => {
      if (window.innerWidth > 1023) {
        Navigation.close()
      }
    })

    let overlay = document.querySelector('.overlay')
    overlay.addEventListener('click', () => {
      Navigation.close()
    })
    document.addEventListener('keyup', (ev) => {
      if (ev.key === "Escape") {
        Navigation.close()
      }
    })
  }

  open() {
    this.menu.classList.add('nav-bar--open')
    document.body.classList.add('no-overflow')
    document.querySelector('.overlay').classList.add('overlay--open')
  }

  static close() {
    Navigation.menu.classList.remove("nav-bar--open")
    document.querySelector('.overlay').classList.remove('overlay--open')
    document.body.classList.remove('no-overflow')
    let openSubmenu = Navigation.menu.querySelector('.open')
    if (openSubmenu) openSubmenu.classList.remove('open')
  }

}

export default Navigation;