class Sidebar {

  constructor(sidebar) {
    this.breakpoint = 1024
    this.sidebar = sidebar
    this.module = this.sidebar.parentElement
    this.sidebarModules = this.module.querySelectorAll('[data-sidebar=true]')
    this.articles = this.module.querySelectorAll('.news-item')
    this.screenSize
    this.initialLayout()
    this.listenForResize()
  }

  listenForResize() {
    addEventListener('resize', () => {
      this.showOrHide()
    })
  }

  initialLayout() {
    this.showOrHide()
  }

  showOrHide() {
    if (window.innerWidth < this.breakpoint) {
      if (this.screenSize === 'small') return;
      this.screenSize = "small"
      this.sidebar.classList.add('hidden')
      // Pull modules out of sidebar
      this.sidebarModules.forEach(sidebarModule => {
        if (this.module.querySelector('.news-list__list')) {
          let offset = Math.max(sidebarModule.getAttribute('data-index') - 1, 0)
          let targetEl = this.module.querySelectorAll('.news-list__list .news-item')[offset]
          if (targetEl) {
            targetEl.before(sidebarModule)
          }
        }
        return
      })
    } else {
      if (this.screenSize === 'large') return;
      this.screenSize = "large"
      this.sidebar.classList.remove('hidden')

      // Put modules into sidebar
      this.sidebarModules.forEach(sidebarModule => {
        this.sidebar.append(sidebarModule)
      })
    }
  }

}

export default Sidebar