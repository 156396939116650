import Dropdown from './Dropdown'
import Navigation from './Navigation'

class Close {

  /**
   *
   * @param {string} except
   */
  static modals(except) {
    let possible = {
      'dropdown': Dropdown.close,
      'menu': Navigation.close,
    }
    for (const [key] of Object.entries(possible)) {
      if (key !== except) {
        possible[key]()
      }
    }
  }

}

export default Close