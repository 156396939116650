class PurchasableArticles {

  constructor() {
    const isPaidArticle = document.querySelector('[data-access="PURCHASABLE"]')
    if (isPaidArticle) {
      this.showPaywall()
    }
  }

  showPaywall() {
    const id = document.querySelector('.article').getAttribute('data-id')
    document.querySelector('.nav-trial').classList.remove('nav-trial--hidden')
  }

}

export default PurchasableArticles